/* styles.css */

.fb-button {
    display: flex;
    /* align-items: center; */
    border: none;
    background-color: #5890FF;
    color: white;
    /* font-size: 14px; */
}


.button-icon {
    font-size: 10px;
    margin-right: 5px; /* Adjust spacing between icon and text */
}
   